













import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Context, Office, Profile } from 'client-website-ts-library/types';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '../components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})

export default class ContractPreparationAdviceForm extends Mixins(View) {
  private formCtx: Context | null = null

  private profile: Profile | null = null

  resolveId() {
    const { id } = this.$route.params;

    if (id.length === 36) {
      // If the ID is 36 long we'll treat it as a guid
      this.updateAgent(id);
    } else {
      API.Slugs.Resolve(id).then((resolved) => {
        if (resolved.Type === 'Profile') {
          this.updateAgent(resolved.Id);
        }
      });
    }
  }

  updateAgent(id: string) {
    API.Profiles.Get(id, true).then((profile) => {
      this.profile = profile;
      this.formCtx = new FormConstructorData([
        {
          Type: ContextItemType.EnquiryTargetProfile,
          Id: profile.Id,
        },
      ]);
    });
  }

  mounted() {
    this.resolveId();
  }
}
